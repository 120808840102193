<template>
  <div class="literature_king">
    <div class="content_box">
      <iframe :src=" `https://apph5.infox-med.com/LiteratureKing/#/?id=${token}&v=${new Date().getTime()}`"
              width="100%" frameborder="0" height="100%"></iframe>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { gainUserInfo } from '@/api/user.js'
export default {
  data () {
    return {
      id: ''
    }
  },
  methods: {
    // 获取用户信息校验token是否过期
    async getUserInfo () {
      if (!this.token) return this.$router.push('/')
      const res = await gainUserInfo({ userId: this.userId })
      if (res.code === 401) {
        this.$router.push('/')
      }
    }
  },
  computed: {
    ...mapGetters(['token', 'userId'])
  },
  created () {
    this.getUserInfo()
    // console.log(this.token)
    // this.id = this.token.split('|')[1]
  }
}
</script>
<style lang="less" scoped>
.literature_king {
  width: 100%;
  .content_box {
    width: 100%;
    height: 100vh;
    padding-bottom: 20px;
    background-color: #f5f7fb;
  }
}
</style>
